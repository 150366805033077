import { ExclamationCircleOutlined } from '@ant-design/icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faChevronLeft, faEye, faPlus, faSave, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from '@reach/router'
import { Button, Col, Form, Input, Modal, notification, Row, Spin, Table } from 'antd'
import { navigate } from 'gatsby'
import React, { useCallback, useEffect, useState } from 'react'
import { isManager } from '../../../../../../api/auth'
import client from '../../../../../../api/client'

const BoxDetails = () => {
  const params = useParams()
  const packageId = params ? params.packageId : null
  const branchId = params ? params.branchId : null
  const [packageDetailsFormRef] = Form.useForm()
  const [loading, setLoading] = useState(true)
  const [packageDetails, setPackageDetails] = useState({})
  const [addOrderModalVisible, setAddOrderModalVisible] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState()
  const [ordersData, setOrdersData] = useState({
    tableData: [],
    dataCount: 0,
  })
  const [paginatedRequestData, setPaginatedRequestData] = useState({
    search: '',
    pagination: {
      current: 1,
      pageSize: -1,
    },
    sorter: {
      field: 'createdDate',
      order: 'descend',
    },
    adminStatus: ['ReadyForSend'],
  })
  const fetchPackageDetails = useCallback(() => {
    client
      .get(`/api/shipments/packages/${packageId}`)
      .then(async (res) => {
        packageDetailsFormRef.setFieldsValue({
          ...res.data,
        })
        setPackageDetails({
          name: res.data.name,
          ordersCount: res.data.orderCount,
          orders: res.data.orders,
        })
        setPaginatedRequestData({
          search: '',
          pagination: {
            current: 1,
            pageSize: -1,
          },
          sorter: {
            field: 'createdDate',
            order: 'descend',
          },
          adminStatus: ['ReadyForSend'],
          shipmentTypes: [res.data.shipmentType],
        })
      })
      .catch((err) => {
        console.error(err.response)
        if (err.response.status === 404) navigate('/admin/404')
      })
      .then(() => setLoading(false))
  }, [packageId, packageDetailsFormRef])

  useEffect(() => {
    fetchPackageDetails()
  }, [fetchPackageDetails])

  const searchOrder = (search) => {
    setPaginatedRequestData({
      ...paginatedRequestData,
      search: search,
      pagination: {
        ...paginatedRequestData.pagination,
        current: 1,
      },
    })
  }

  const fetchOrderList = useCallback(() => {
    client
      .post(`/api/admin/orders/paginated`, { ...paginatedRequestData, branchId: parseInt(branchId) })
      .then(async (res) => {
        setOrdersData({
          tableData: res.data.data,
          dataCount: res.data.total,
        })
      })
      .catch((error) => console.error(error))
      .then(() => setLoading(false))
  }, [paginatedRequestData, branchId])

  const removeOrder = (barcode) => {
    Modal.confirm({
      title: 'Та энэ илгээмжийг хасах итгэлтэй байна уу?',
      icon: <ExclamationCircleOutlined />,
      className: 'sb-modal',
      okText: 'Хасаx',
      okType: 'danger',
      cancelText: 'Цуцлах',
      centered: true,
      onOk: () => {
        client
          .put(`/api/shipments/packages/${packageId}/addorder/${barcode}`)
          .then(async (res) => {
            fetchPackageDetails()
            notification.success({ message: 'Илгээмжийг амжилттай хаслаа!' })
          })
          .catch((err) => console.error({ err }))
      },
    })
  }

  const addOrder = () => {
    const data = selectedOrder ? selectedOrder : []
    client
      .post(`/api/shipments/packages/${packageId}/addorder`, data)
      .then(async (res) => {
        fetchPackageDetails()
        setAddOrderModalVisible(false)
        setPaginatedRequestData({
          ...paginatedRequestData,
          search: '',
          pagination: {
            ...paginatedRequestData.pagination,
            current: 1,
          },
        })
      })
      .catch((err) => {
        if (err.status === 404) notification.warning({ message: 'Илгээмж олдсонгүй!' })
        else console.error({ err })
      })
  }

  const updatePackageDetails = (values) => {
    const endpoint = `/api/shipments/packages/${packageId}`
    const data = { name: values.name }
    client
      .put(endpoint, data)
      .then(async (res) => {
        notification.success({ message: 'Багцын нэрийг амжилттай солилоо!' })
        fetchPackageDetails()
      })
      .catch((err) => console.error({ err }))
  }

  const ordersColumns = isManager()
    ? [
        {
          title: 'Дугаар',
          className: 'text-center',
          dataIndex: 'code',
        },
        {
          title: 'Нийт жин',
          className: 'text-center',
          dataIndex: 'weight',
          render: (weight) => `${weight} кг`,
        },
        {
          title: 'Нийт төлбөр',
          className: 'text-center',
          dataIndex: 'amount',
          render: (amount) => `$${amount}`,
        },
        {
          title: 'Илгээгч',
          className: 'text-center',
          dataIndex: 'sender',
        },
        {
          title: 'Хүлээн авагч',
          className: 'text-center',
          dataIndex: 'receiver',
        },
      ]
    : [
        {
          title: 'Дугаар',
          className: 'text-center',
          dataIndex: 'code',
        },
        {
          title: 'Нийт жин',
          className: 'text-center',
          dataIndex: 'weight',
          render: (weight) => `${weight} кг`,
        },
        {
          title: 'Нийт төлбөр',
          className: 'text-center',
          dataIndex: 'amount',
          render: (amount) => `$${amount}`,
        },
        {
          title: 'Илгээгч',
          className: 'text-center',
          dataIndex: 'sender',
        },
        {
          title: 'Хүлээн авагч',
          className: 'text-center',
          dataIndex: 'receiver',
        },
        {
          title: 'Үйлдэл',
          className: 'text-center',
          render: (record) => (
            <>
              <Button className="btn-sb btn-sb-primary me-3" style={{ width: 40 }} onClick={() => navigate(`/admin/orders/${record.id}`)}>
                <FontAwesomeIcon icon={faEye} size="sm" className="mx-2" />
              </Button>
              <Button className="btn-sb btn-sb-danger" style={{ width: 40 }} onClick={() => removeOrder(record.code)}>
                <FontAwesomeIcon icon={faTrashAlt} size="sm" className="mx-2" />
              </Button>
            </>
          ),
        },
      ]

  const addOrderColumns = [
    {
      title: 'Код',
      className: 'text-center',
      dataIndex: 'code',
    },
    {
      title: 'Жин',
      className: 'text-center',
      dataIndex: 'weight',
      responsive: ['md'],
      render: (weight) => `${weight} кг`,
    },
    {
      title: 'Үнэ',
      className: 'text-center',
      responsive: ['md'],
      render: (record) => {
        return <span className="w-100 d-block">{'$' + record.amount}</span>
      },
    },
    {
      title: 'Илгээгч',
      className: 'text-center',
      responsive: ['md'],
      render: (record) => `${record.sender?.lastName?.charAt(0)}. ${record.sender?.firstName}`,
    },
    {
      title: 'Хүлээн авагч',
      className: 'text-left',
      responsive: ['md'],
      render: (record) => `${record.receiver?.lastName?.charAt(0)}. ${record.receiver?.firstName}`,
    },
  ]

  return (
    <>
      <Row gutter={[30, 20]}>
        <Col span={24} style={{ minHeight: 50 }}>
          <Button type="link" className="sb-arrow-back" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} size="xs" />
            <span>Буцах</span>
          </Button>
        </Col>
        <Col flex="auto">
          <h4>'{packageDetails.name}' дэлгэрэнгүй мэдээлэл</h4>
        </Col>
        {!isManager() && (
          <Col flex="250px">
            <Button className="ant-btn btn-sb btn-sb-primary sb-shadow w-100" form="package-details" htmlType="submit">
              <FontAwesomeIcon icon={faSave} size="sm" className="mx-2" />
              Хадгалах
            </Button>
          </Col>
        )}
        <Col span={24}>
          <Spin size="large" spinning={loading}>
            <Form name="package-details" form={packageDetailsFormRef} onFinish={updatePackageDetails}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item name="name" label="Багцийн нэр">
                    <Input placeholder="Нэр" className="sb-input sb-shadow" disabled={isManager() ? true : false} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="totalAmountInUSA" label="АНУ-д төлөх төлбөр">
                    <Input placeholder="АНУ-д" className="sb-input sb-shadow" prefix="$" size="large" disabled />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="totalAmountInMGL" label="Монголд төлөх төлбөр">
                    <Input placeholder="Монголд" className="sb-input sb-shadow" prefix="$" size="large" disabled />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="totalAmount" label="Нийт төлбөр">
                    <Input placeholder="Нийт төлбөр" className="sb-input sb-shadow" prefix="$" size="large" disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col flex="auto" style={tableTitleStyle}>
              <span>Илгээмжийн жагсаалт ({packageDetails.ordersCount})</span>
            </Col>
            {!isManager() && (
              <Col flex="200px">
                <Button
                  className="ant-btn btn-sb btn-sb-primary sb-shadow w-100"
                  onClick={() => {
                    setAddOrderModalVisible(true)
                    fetchOrderList()
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} size="sm" className="mx-2" />
                  Ачаа нэмэх
                </Button>
              </Col>
            )}
          </Row>
          <Row gutter={[8, 8]}>
            <Table
              className="sb-table w-100 mt-3"
              dataSource={packageDetails.orders}
              loading={loading}
              columns={ordersColumns}
              rowKey={(record) => record.id}
              locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
            />
          </Row>
        </Col>
      </Row>
      <Modal
        title="Ачаа нэмэх"
        open={addOrderModalVisible}
        onCancel={() => {
          setAddOrderModalVisible(false)
          setPaginatedRequestData({
            ...paginatedRequestData,
            search: '',
            pagination: {
              ...paginatedRequestData.pagination,
              current: 1,
            },
          })
        }}
        className="sb-modal"
        width={750}
        destroyOnClose
        centered
        closable={false}
        footer={[
          <Row justify="center" key="branch-modal">
            <Col span={18}>
              <Button className="btn-sb btn-sb-primary w-100" onClick={() => addOrder()}>
                Нэмэх
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Row gutter={8}>
          <Col span={12}>
            <Input
              placeholder="Хайлт"
              className="sb-input sb-shadow"
              onChange={(e) => searchOrder(e.target.value)}
              suffix={<FontAwesomeIcon icon={faSearch} size="xs" />}
            />
          </Col>
          <Table
            className="sb-table w-100 mt-3"
            dataSource={ordersData.tableData}
            loading={loading}
            columns={addOrderColumns}
            rowKey={(record) => record.code}
            locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
            style={{ maxHeight: '430px', minHeight: '430px', overflow: 'auto' }}
            pagination={false}
            rowSelection={{
              type: 'checkbox',
              onChange: (selectedRowKey) => {
                setSelectedOrder(selectedRowKey)
              },
            }}
          />
        </Row>
      </Modal>
    </>
  )
}

export default BoxDetails

const tableTitleStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: 24,
}
